import React from 'react'
import clsx from 'clsx'
import { StaticImage } from 'gatsby-plugin-image'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Layout, SEO } from 'components'

const useStyles = makeStyles(theme => ({
  center: {
    margin: '20px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    fontWeight: 'bold',
    textAlign: 'justify',
    margin: '10px 0px',
    '& p ': {
      margin: '10px 0px',
    },
  },
  decoration: {
    width: '70%',
    minHeight: '100%',
    margin: '0 auto',
    '& img': { borderRadius: '12px' },
  },
  lastChild: {
    paddingBottom: '40px',
  },
  mainContainer: {
    paddingTop: '50px',
    width: '1050px',
    margin: '0 auto',
    [theme.breakpoints.up('xs')]: {
      paddingTop: '20px',
      width: '280px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '20px',
      width: '320px',
    },
    '@media (min-width: 768px)': {
      paddingTop: '20px',
      width: '690px',
    },
    '@media (min-width: 1200px) ': {
      paddingTop: '20px',
      width: '1050px',
    },
  },
  tittle: {
    color: theme.palette.text.gray,
    fontWeight: 'bold',
    marginBottom: '4px',
    marginTop: '4px',
    fontSize: '1.5rem',
  },
  subTittle: {
    color: theme.palette.text.gray,
    fontWeight: 'bold',
    marginBottom: '4px',
    marginTop: '4px',
    fontSize: '1.3rem',
    paddingTop: '15px',
  },
  subSubTittle: {
    color: theme.palette.text.gray,
    fontWeight: 'bold',
    marginBottom: '4px',
    marginTop: '4px',
    fontSize: '1.1rem',
    paddingTop: '15px',
  },
  sections: {
    '& p': {
      paddingTop: '10px',
      textAlign: 'justify',
    },
  },
}))

const AboutPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <div className={classes.mainContainer}>
        <SEO title="About us" />
        <Typography className={classes.tittle}>About Us</Typography>
        <div className={classes.center}>
          <StaticImage
            src={`../assets/about_images/image1.jpg`}
            alt="Smthy"
            height={200}
            width={200}
          />
        </div>
        <Typography className={classes.content}>
          Welcome to Smthy! We are an online marketplace enabling designers,
          consumers, and makers to connect with each other bringing digital
          creations into the real world. Smthy allows anyone to find designs and
          have them 3d printed by one of our community of makers. We handle the
          transactional aspects so both parties can be sure they will be
          satisfied with the outcome and focus on what matters to them. Our
          mission is simple: Bridge the gap between imagination and realization.
        </Typography>

        <div className={clsx(classes.center, classes.decoration)}>
          <StaticImage
            src={`../assets/about_images/image2.png`}
            alt="Welcome to Smthy"
          />
        </div>
        <div className={classes.content}>
          <Typography className={clsx(classes.center, classes.subSubTittle)}>
            Makers
          </Typography>
          <Typography>
            Smthy allows you to provide your services to the world and get paid
            for it. Whatever you charge for or bid on a listing is what you will
            get paid once the user accepts the item in the app, and you have
            shipped it. No seller fees! We provide tools to assist with the
            process as we slice the models we receive, provide you with
            materials & time estimates, and we provide Shipping labels from UPS.
            Whether you have a specific set of products you want to produce or
            if you are interested in finding new and interesting challenges,
            Smthy provides a platform for you to grow as a maker and worry less
            about the additional challenges of running your business.
          </Typography>
          <div className={clsx(classes.center, classes.decoration)}>
            <StaticImage
              src={`../assets/about_images/image3.png`}
              alt="Makers"
            />
          </div>
          <Typography className={clsx(classes.center, classes.subSubTittle)}>
            Designers
          </Typography>
          <Typography>
            Smthy allows you to upload designs directly, or link with your
            Thingiverse account and provide your models to the general public to
            print. Right now, we only recommend using designs created under the
            Creative Commons licenses as our next major feature will be to
            provide design fees to original designers, but we have not launched
            that yet. Stay tuned!
          </Typography>
          <div className={clsx(classes.center, classes.decoration)}>
            <StaticImage
              src={`../assets/about_images/image4.jpg`}
              alt="Designers"
            />
          </div>
          <Typography className={clsx(classes.center, classes.subSubTittle)}>
            End Users
          </Typography>
          <Typography>
            Smthy provides a platform for you to bring dreams and ideas to the
            real world. As 3D printing becomes more prevalent, the sources of
            designs are continuing to grow. Many artists provide their content
            in 3D printable format, replacement parts that were once hard to
            find are just a click away! Home decor, fashion, jewelry, gaming
            items, and entire cosplay outfits, are just a small fraction of the
            things you can have 3d printed, but not everyone knows how to print,
            or someone who owns a printer. Smthy would like to introduce you to
            Makers who would be happy to help!
          </Typography>
          <div
            className={clsx(
              classes.center,
              classes.decoration,
              classes.lastChild
            )}
          >
            <StaticImage
              src={`../assets/about_images/image5.jpg`}
              alt="End Users"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
